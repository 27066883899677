import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { UserContext } from '../Dashboard';

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "id",
        type: "number",
        width: 50
    },
    {
        field: "name",
        headerName: "Product Name",
        type: "string"
    },
    {
        field: "address",
        headerName: "Address",
        type: "string"
    },
    {
        field: "contactNo",
        headerName: "Contact No",
        type: "string"
    },
    {
        field: "email",
        headerName: "Email",
        type: "string"
    },
    {
        field: "ownerName",
        headerName: "Owner Name",
        type: "string"
    },
    {
        field: "brandName",
        headerName: "Brand Name",
        type: "string"
    },
    {
        field: "GSTIN",
        headerName: "GSTIN",
        type: "string"
    },
    {
        field: "warehouseId",
        headerName: "Warehouse Id",
        type: "string"
    },
]

export default function ListCompanies() {
  const user = React.useContext(UserContext);
  const companies = React.useMemo(() => user?.warehouses.map(Warehouse => Warehouse.companies).flat(4), [user]);

  return (
    <Box>
        <DataGrid
            rows={companies}
            columns={columns}
        />
    </Box>
  )
}
