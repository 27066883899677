import React, { useState, useEffect, FormEventHandler } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import instance from "../../instance";

export interface EditUserProps {
  updateFields: Function,
  fields: {[key: string]: string},
  updateUrl: string
}

const excluded = ["id", "username", "userId"];

const EditFields: React.FC<EditUserProps> = ({ updateFields, fields, updateUrl }) => {
  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => {
    setOpen(false);
  };

  function handleEditUser() {
    setOpen(true);
  }

  const handleSave = (e: any) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const data = new FormData(form);
    const dataValues = Object.fromEntries(Object.keys(fields).map(value => [value, data.get(value) || fields[value]]))

    instance
      .post(updateUrl, dataValues)
      .then((result) => console.log(result.data))
      .then(() => updateFields())
      .then(_ => setOpen(false))
      .catch(console.error);
  };

  return (
    <>
      <MenuItem onClick={handleEditUser}>Edit</MenuItem>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            maxHeight: "100vh",
            overflowY: "scroll"
          }}>
          <Typography variant='h6' gutterBottom>
            Edit Details
          </Typography>
          <form onSubmit={handleSave}>
            {
                Object.keys(fields).map((key, index) => {
                    const disabled = excluded.includes(key);
                    return (
                    <TextField
                        disabled={disabled}
                        key={index}
                        label={key.toUpperCase()}
                        name={key}
                        fullWidth
                        defaultValue={fields[key]}
                        margin='normal'
                        helperText={disabled && "You can't change this field"}
                    />
                )})
            }
            <Box mt={2}>
              <Button
                type='submit'
                variant='contained'
                color='primary'>
                Save
              </Button>
              <Button
                variant='outlined'
                onClick={onClose}
                style={{ marginLeft: "10px" }}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default EditFields;
