import papa from 'papaparse';

export async function csv2Json(file: File): Promise<Array<Object>> {
    return new Promise((resolve) => {
        papa.parse<unknown, File>(file, {
            skipEmptyLines: true,
            header: true,
            complete: (data) => {
                resolve(data.data as Array<Object>)
            }
        })
    })
}