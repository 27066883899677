import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  MainListItems,
  secondaryListItems,
} from "../components/users/listitems";
import BasicMenu from "../components/users/BasicMenu";
import ProfileMenu from "../components/users/ProfileMenu";
import FullPageLoader from "../components/FullPageLoader";
import { useNavigate } from "react-router-dom";
import { FullDetails } from "../interfaces";
import instance from "../instance";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#46564d",
      light: "#42a5f5",
      dark: "#1565c0",
      contrastText: "#fff",
    },
  },
});

export const UserContext = React.createContext<FullDetails | null>(null);
export const SelectedWarehouseContext = React.createContext<{
  name: string;
  id: number;
} | null>(null);
export const ForceUpdateContext = React.createContext({
  updated: 0,
  forceUpdate: () => {},
});

export default function Dashboard({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState<FullDetails | null>(
    null
  );
  const [updated, setUpdate] = React.useState<number>(0);
  function forceUpdate() {
    setUpdate(updated + 1);
  }

  const [selectedWarehouse, selectWarehouse] = React.useState<{
    name: string;
    id: number;
  } | null>(null);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    instance
      .get("/users/getFullDetails")
      .then((result) => {
        setUserDetails(result.data);
        const war = result.data?.warehouses[0] ?? null;
        selectWarehouse(war);
      })
      .catch((err) => {
        navigate(`/login?err=${err.response?.status || err.code}`);
      });
  }, [navigate, updated]);

  return (
    <ThemeProvider theme={defaultTheme}>
      {userDetails ? (
        <ForceUpdateContext.Provider value={{updated, forceUpdate}}>
          <SelectedWarehouseContext.Provider value={selectedWarehouse}>
            <UserContext.Provider value={userDetails}>
              <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position='absolute' open={open}>
                  <Toolbar
                    sx={{
                      pr: "24px", // keep right padding when drawer closed
                    }}
                    variant='dense'>
                    <IconButton
                      edge='start'
                      color='inherit'
                      aria-label='open drawer'
                      onClick={toggleDrawer}
                      sx={{
                        marginRight: "36px",
                        ...(open && { display: "none" }),
                      }}>
                      <MenuIcon />
                    </IconButton>
                    <Typography
                      component='h1'
                      variant='h6'
                      color='inherit'
                      noWrap
                      sx={{ flexGrow: 1 }}>
                      Dashboard
                    </Typography>
                    {selectedWarehouse && (
                      <BasicMenu
                        selectItem={selectWarehouse}
                        selectedItem={selectedWarehouse}
                        items={userDetails.warehouses}
                      />
                    )}
                    <IconButton color='inherit'>
                      <ProfileMenu />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <Drawer variant='permanent' open={open}>
                  <Toolbar
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      px: [1],
                    }}>
                    <IconButton onClick={toggleDrawer}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </Toolbar>
                  <Divider />
                  <List component='nav'>
                    <MainListItems />
                    <Divider sx={{ my: 1 }} />
                    {secondaryListItems}
                  </List>
                </Drawer>
                <Box
                  component='main'
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                  }}>
                  <Toolbar />
                  {children}
                </Box>
              </Box>
            </UserContext.Provider>
          </SelectedWarehouseContext.Provider>
        </ForceUpdateContext.Provider>
      ) : (
        <>
          <FullPageLoader text='Getting User Details' />
        </>
      )}
    </ThemeProvider>
  );
}
