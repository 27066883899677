import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  ButtonGroup,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import instance from "../../instance";

const AddFields: React.FC<{ title: string, updateFields: Function, fields: string[], addUrl: string }> = ({title, updateFields, fields, addUrl }) => {
  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => {
    setOpen(false);
  };

  function handleEditUser() {
    setOpen(true);
  }

  const handleAdd = (e: any) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const data = new FormData(form);

    instance
      .post(addUrl, Object.fromEntries(fields.map(value => [value, data.get(value)])))
      .then((result) => updateFields(result.data))
      .then(() => setOpen(false))
      .catch(console.log);
  };

  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        onClick={handleEditUser}
        sx={{ borderRadius: 3, m: 2, width: 200 }}>
        <AddIcon /> NEW {title}
      </Button>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            maxHeight: "100vh",
            overflowY: 'scroll'
          }}>
          <form onSubmit={handleAdd}>
            <Typography variant='h6' gutterBottom>
              Add New {title}
            </Typography>
            {
                fields.map((value, index) => (
                    <TextField
                        key={index}
                        label={value.toLocaleUpperCase()}
                        fullWidth
                        name={value}
                        margin='normal'
                    />
                ))
            }
            <Box mt={2}>
              <ButtonGroup fullWidth>
                <Button
                  variant='contained'
                  color='secondary'
                  type="submit">
                  Add
                </Button>
                <Button variant='outlined' onClick={onClose}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddFields;
