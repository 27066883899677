import axios from 'axios';

const instance = axios.create({
  baseURL: '/api', // Replace with your API base URL
  timeout: 10000, // Timeout in milliseconds (10 seconds)
  headers: {
    'Content-Type': 'application/json',
    // Add any headers you need
  },
});

export default instance;