import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FactoryRounded, PeopleTwoTone, UploadSharp } from '@mui/icons-material';
import {Link} from "react-router-dom";
import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import SubMenu from './SubMenu';


export const MainListItems = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentSubItems, setCurrentSubItems] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleMouseEnter = (event, subItems) => {
    setAnchorEl(event.currentTarget);
    setCurrentSubItems(subItems);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const dashboardSubItems = [
    { text: 'Overview', link: "/dashboard" },
    { text: 'Analytics', link: "/analytics" },
    { text: 'Reports', link: "/reports" },
  ];

  const warehousesSubItems = [
    { text: 'List' },
    { text: 'Map' },
    { text: 'Statistics' },
  ];

  const companiesSubItems = [
    { text: 'List Companies', link: "/companies" },
    { text: 'Add Company', link: "/companies/add" }
  ];

  const channelsSubItems = [
    { text: 'List Channels', link: "/channels" },
    { text: 'Add New Channel', link: "/channels/add" },
  ];

  const productsSubItems = [
    { text: 'List Products', link: "/products" },
    { text: 'List Orders', link: "/orders/new-orders" }
  ];

  return (
    <React.Fragment>
      <ListItemButton  onClick={(e) => handleMouseEnter(e, dashboardSubItems)}>
        <ListItemIcon>
          <PeopleTwoTone />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={(e) => handleMouseEnter(e, warehousesSubItems)}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Warehouses" />
      </ListItemButton>
      <ListItemButton  onClick={(e) => handleMouseEnter(e, companiesSubItems)}>
        <ListItemIcon>
          <FactoryRounded />
        </ListItemIcon>
        <ListItemText primary="Companies" />
      </ListItemButton>
      <ListItemButton  onClick={(e) => handleMouseEnter(e, channelsSubItems)}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Channels" />
      </ListItemButton>
      <ListItemButton onClick={(e) => handleMouseEnter(e, productsSubItems)}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItemButton>
      <SubMenu items={currentSubItems} anchorEl={anchorEl} open={open} onClose={handleMouseLeave} />
    </React.Fragment>
  );
};


export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Additional Options
    </ListSubheader>
    <ListItemButton LinkComponent={Link} to="/upload">
      <ListItemIcon>
        <UploadSharp />
      </ListItemIcon>
      <ListItemText primary="Upload Data" />
    </ListItemButton>
    {/* <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton> */}
  </React.Fragment>
);