import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';

const order = {
    invoiceNumber: 'Empty',
    orderId: 'M605528873-8986041',
    orderDate: 'July 27, 2024 08:54:07 AM',
    shipmentTracker: '',
    netProfit: '₹ 0.00',
    netPaymentReceived: '₹ 0.00',
    items: [
      {
        subOrderId: '71076356',
        sku: 'HANDN-489 XL - AAYUMI-ANARKALI',
        sellingPrice: '₹ 399.00',
        shippingPrice: '₹ 0.00',
        total: '₹ 399.00'
      }
    ],
    fees: {
      commission: '₹ 0.00 (0.00%)',
      shipping: '₹ 0.00',
      payment: '₹ 0.00',
      cancellation: '₹ 0.00',
      other: '₹ 0.00',
      gst: '₹ 0.00',
      total: '₹ 0.00'
    }
  };

const OrderView = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Typography variant="h4">Order Details</Typography>
        <Button variant="contained" color="primary">Related Actions</Button>
      </Box>
      
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6">Orders</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Invoice Number:</TableCell>
                <TableCell>{order.invoiceNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Order:</TableCell>
                <TableCell>{order.orderId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Order Date:</TableCell>
                <TableCell>{order.orderDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shipment Tracker:</TableCell>
                <TableCell>{order.shipmentTracker}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Net Profit:</TableCell>
                <TableCell>{order.netProfit}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Net Payment Rcvd:</TableCell>
                <TableCell>{order.netPaymentReceived}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6">Order Item Details</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SubOrder Id / Qty</TableCell>
                <TableCell>SKU Code - SKU Name</TableCell>
                <TableCell>Selling Price Per Qty</TableCell>
                <TableCell>Shipping Per Qty</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items.map((item) => (
                <TableRow key={item.subOrderId}>
                  <TableCell>{item.subOrderId}</TableCell>
                  <TableCell>{item.sku}</TableCell>
                  <TableCell>{item.sellingPrice}</TableCell>
                  <TableCell>{item.shippingPrice}</TableCell>
                  <TableCell>{item.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box>
        <Typography variant="h6">Total Channel Fees (Charged By Channel)</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Commission Fees</TableCell>
                <TableCell>Shipping Fees</TableCell>
                <TableCell>Payment Fees</TableCell>
                <TableCell>Cancellation Fees</TableCell>
                <TableCell>Other Fees</TableCell>
                <TableCell>GST</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{order.fees.commission}</TableCell>
                <TableCell>{order.fees.shipping}</TableCell>
                <TableCell>{order.fees.payment}</TableCell>
                <TableCell>{order.fees.cancellation}</TableCell>
                <TableCell>{order.fees.other}</TableCell>
                <TableCell>{order.fees.gst}</TableCell>
                <TableCell>{order.fees.total}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default OrderView;
