import { Box, Typography } from '@mui/material'
import { lineSpinner } from 'ldrs'
lineSpinner.register()


export default function Loader({size}: {size: number}) {
  return (
    <>
      <l-line-spinner
        size={size}
        stroke="3"
        speed="1" 
        color="black" 
      ></l-line-spinner>
    </>
  )
}
