import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import {
  MoreVertTwoTone as MoreVertIcon,
  PersonAddAltTwoTone,
} from "@mui/icons-material";
import AddFields from "../../components/admin/AddFields";
import EditFields from "../../components/admin/EditFields";
import instance from "../../instance";

interface EditMenuProps {
  fields: {
    [key: string]: string;
  };
  updateFields: Function;
}

const EditMenu: React.FunctionComponent<EditMenuProps> = ({
  fields,
  updateFields,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={(event) => handleMenuClick(event)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <EditFields
          fields={fields}
          updateUrl='/admin/editCompany'
          updateFields={updateFields}
        />
      </Menu>
    </>
  );
};

export default function Companies() {
  const [fields, setFields] = React.useState([]);
  React.useEffect(() => {
    updateFields();
  }, []);

  function updateFields() {
    instance
      .get("/admin/getCompanies")
      .then((result) => {
        setFields(result.data);
      })
      .catch(console.error);
  }

  return (
    <Box sx={{ width: "100%", px: 0.5 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "self-end",
        }}>
        <AddFields
          title='Company'
          updateFields={updateFields}
          addUrl='/admin/addCompany'
          fields={[
            "name",
            "address",
            "warehouseId",
            "contactNo",
            "email",
            "ownerName",
            "brandName",
            "GSTIN",
          ]}
        />
      </Box>
      <DataGrid
        autoHeight
        rows={fields}
        columns={[
          { field: "id", headerName: "ID", width: 50 },
          {
            field: "name",
            headerName: "Name",
            width: 100,
          },
          {
            field: "address",
            headerName: "Address",
            width: 100,
          },
          {
            field: "warehouseId",
            headerName: "Warehouse Id",
            width: 50,
          },
          {
            field: "contactNo",
            headerName: "Contact No",
            width: 100,
          },
          {
            field: "email",
            headerName: "Email",
            width: 150,
          },
          {
            field: "ownerName",
            headerName: "Owner Name",
            width: 100,
          },
          {
            field: "brandName",
            headerName: "Brand Name",
            width: 100,
          },
          {
            field: "GSTIN",
            headerName: "GSTIN",
            width: 100,
          },
          {
            field: "action",
            headerName: "Action",
            description: "Here you can update users",
            sortable: false,
            width: 50,
            renderCell: (params) => (
              <EditMenu fields={params.row} updateFields={updateFields} />
            ),
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 15, 20, 50, 100]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
