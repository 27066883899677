import React, { useState, ChangeEvent } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  ButtonGroup,
  Chip,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { SelectedWarehouseContext, UserContext } from "../Dashboard";
import { CSVLink } from "react-csv";
import productTemplate from "./../../templates/Products.json";
import { useSnackbar } from "notistack";
import { csv2Json } from "../../function";
import Loader from "../../components/Loader";
import { ForkRight } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import instance from "../../instance";

interface FileType {
  name: string;
  file: any;
  url: string;
}

const fileTypes: FileType[] = [
  {
    name: "Product",
    file: productTemplate,
    url: "/products/upload",
  },
];

enum Status {
  idle = "idle",
  success = "success",
  processing = "processing",
  uploading = "uploading",
  error = "Error"
}

const UploadData: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedWarehouse = React.useContext(SelectedWarehouseContext);
  const user = React.useContext(UserContext);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedFileType, setSelectedFileType] = useState<number>(0);
  const [status, setStatus] = useState<Status>(Status.idle);
  const [data, setData] = useState<Array<{ [key: string]: any }>>([]);

  // Function to handle file upload
  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    // Process the uploaded file (e.g., read CSV data)
    if (file) {
      setStatus(Status.processing);
      const products = await csv2Json(file);
      
      setStatus(Status.uploading);
      instance
        .post("/products/upload", {
          warehouseId: selectedWarehouse?.id,
          companyId: selectedCompany,
          products,
        })
        .then((result) => {
          setStatus(Status.success);
          setData(result.data);
        })
        .catch((err) => {
          setStatus(Status.error);
          enqueueSnackbar({
            message: err.message,
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom",
            },
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar({
        message: "Select a valid file",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
        variant: "error",
      });
    }
  };

  // Function to handle company selection
  const handleCompanyChange = (event: SelectChangeEvent<string>) => {
    setSelectedCompany(event.target.value as string);
  };

  // Function to handle file type selection (e.g., product data)
  const handleFileTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedFileType(Number(event.target.value));
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Typography variant='h5' gutterBottom>
          Upload Data
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Company</InputLabel>
              <Select
                value={selectedCompany}
                onChange={handleCompanyChange}
                label='Select Company'>
                {user?.warehouses
                  .find((warehouse) => selectedWarehouse?.id === warehouse.id)
                  ?.companies.map((value) => (
                    <MenuItem value={value.id} key={value.id}>
                      {value.name}
                    </MenuItem>
                  )) || <MenuItem>No Companies Found</MenuItem>}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Data Type</InputLabel>
              <Select
                defaultValue={String(selectedFileType)}
                onChange={handleFileTypeChange}
                label='Select Data Type'>
                {fileTypes.map((value, index) => (
                  <MenuItem value={String(index)}>{value.name}</MenuItem>
                ))}
                {/* Add more data types as needed */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <input
              accept='.csv'
              style={{ display: "none" }}
              id='csv-upload'
              type='file'
              onChange={handleFileUpload}
            />
            <label htmlFor='csv-upload'>
              <ButtonGroup>
                <Button
                  variant='outlined'
                  component='span'
                  startIcon={
                    status === Status.idle ? (
                      <CloudUploadIcon />
                    ) : status === Status.success ? (
                      <ForkRight />
                    ) : (
                      <Loader size={10} />
                    )
                  }
                  disabled={status !== Status.idle || !selectedCompany}>
                  {status === Status.idle
                    ? "Upload CSV File"
                    : status.toString()}
                </Button>
                {status !== Status.idle && (
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setStatus(Status.idle);
                      setData([]);
                    }}>
                    Reset
                  </Button>
                )}
              </ButtonGroup>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Button variant='outlined'>
              <CSVLink
                data={fileTypes[selectedFileType].file}
                style={{ textDecoration: "none", color: "inherit" }}>
                Download {fileTypes[selectedFileType].name} Template
              </CSVLink>
            </Button>
          </Grid>
        </Grid>

        {data && data[0] && (
          <DataGrid
            rows={data}
            columns={Object.keys(data[0]).map(
              (value): GridColDef => ({
                field: value,
                headerName: value,
                renderCell:
                  value === "uploaded"
                    ? (params) => {
                        return (
                          <Chip
                            label={params.value ? "uploaded" : "!Error"}
                            color={params.value ? "success" : "error"}
                          />
                        );
                      }
                    : undefined,
              })
            )}
            getRowId={(row) => row.index}
          />
        )}
      </Paper>
    </Container>
  );
};

export default UploadData;
