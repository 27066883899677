import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { UserContext } from '../Dashboard';
import { Button } from '@mui/material';

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "id",
        type: "number",
        width: 50
    },
    {
        field: "name",
        headerName: "Channel Name",
        type: "string"
    },
    {
        field: "type",
        headerName: "Type",
        type: "string"
    },
    {
        field: "companyId",
        headerName: "Company Id",
        type: "string"
    },
    {
        field: "authenticate",
        headerName: "Authenticate",
        renderCell: (params) => {
            return <Button onClick={() => window.location.assign(`/api/auth/flipkart?state=${params.id}`)}>activate</Button>
        }
    }
]

export default function ListChannels() {
  const user = React.useContext(UserContext);
  const companies = React.useMemo(() => user?.warehouses.map(Warehouse => Warehouse.companies.map(company => company.channels)).flat(4), [user]);

  return (
    <Box>
        <DataGrid
            rows={companies}
            columns={columns}
        />
    </Box>
  )
}
