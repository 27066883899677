
import { createBrowserRouter, Outlet, Navigate } from 'react-router-dom';
import LoginPage from './pages/admin/LoginPage';
import DashboardPage from './pages/admin/DashboardPage';
import Users from './pages/admin/Users';
import Warehouses from './pages/admin/Warehouses';
import Companies from './pages/admin/Companies';
import Channels from './pages/admin/Channels';
import Products from './pages/admin/Products';
import LoginUser from './pages/LoginUser';
import Dashboard from './pages/Dashboard';
import UploadData from './pages/upload/UploadData';
import FullPageLoader from './components/FullPageLoader';
import ListProducts from './pages/products/ListProducts';
import ListCompanies from './pages/companies/ListCompanies';
import AddCompany from './pages/companies/AddCompany';
import ListChannels from './pages/channels/ListChannels';
import EditProduct from './pages/products/EditProduct';
import AddChannels from './pages/channels/AddChannels';
import NewOrders from './pages/orders/NewOrders';
import OrderView from './pages/orders/OrderView';

const router = createBrowserRouter([
  {
    path: "admin",
    children: [
      {
        path: "",
        element: <Navigate to="/admin/login" />
      },
      {
        path: "login",
        element: <LoginPage />
      },
      {
        path: "dashboard",
        element: <DashboardPage ><Outlet /></DashboardPage>,
        children: [
          {
            path: "",
            element: <Users />
          },
          {
            path: "warehouses",
            element: <Warehouses />
          },
          {
            path: 'companies',
            element: <Companies />
          },
          {
            path: "channels",
            element: <Channels />
          },
          {
            path: "products",
            element: <Products />
          }
        ]
      }
    ]
  },
  {
    path: "login",
    element: <LoginUser />
  },
  {
    element: <Dashboard><Outlet /></Dashboard>,
    errorElement: <FullPageLoader text={"Not Found"} />,
    children: [
      {
        path: "",
        element: <Navigate to='/login' />
      },
      {
        path: "dashboard",
        element: <h1>Under Developement</h1>
      },
      {
        path: "upload",
        element: <UploadData />
      },
      {
        path: "products",
        children: [
            {
              path: '',
              element: <ListProducts />
            },
            {
              path: "edit/:id",
              element: <EditProduct />
            }
          ]
      },
      {
        path: "companies",
        children: [
          {
            path: "",
            element: <ListCompanies />
          },
          {
            path: "add",
            element: <AddCompany />
          }
        ]
      },
      {
        path: "channels",
        children: [
          {
            path: "",
            element: <ListChannels />
          },
          {
            path: "add",
            element: <AddChannels />
          }
        ]
      },
      {
        path: "orders",
        children: [
          {
            path: "new-orders",
            element: <NewOrders />
          },
          {
            path: "view/:id",
            element: <OrderView />
          }
        ]
      }
    ]
  }
])

export default router;
