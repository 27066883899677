import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface MenuItemDetails {
    name: string,
    id: any
}

interface MenuFunction {
    selectedItem: MenuItemDetails,
    items: MenuItemDetails[],
    selectItem: Function
}

export default function BasicMenu({selectedItem, items, selectItem}: MenuFunction) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (value: MenuItemDetails) => {
    selectItem(value);
    handleClose();
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        variant='contained'
        title='Warehouse'
        sx={{textTransform: "none"}}
      >
        {selectedItem.name}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
            items.map((value, index) => (
                <MenuItem key={index} onClick={() => handleClick(value)}>{value.name}</MenuItem>
            ))
        }
      </Menu>
    </div>
  );
}
