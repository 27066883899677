import React from 'react';
import { List, ListItem, ListItemText, Popover, Paper, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';

const SubMenu = ({ items, anchorEl, open, onClose }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Paper>
        <List>
          {items.map((item, index) => (
            <ListItemButton LinkComponent={Link} to={item.link} key={index}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}
        </List>
      </Paper>
    </Popover>
  );
};

export default SubMenu;
