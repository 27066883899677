import { Box, Typography } from '@mui/material'
import { lineSpinner } from 'ldrs'
lineSpinner.register()


export default function FullPageLoader({text}) {
  return (
    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh"}}>
      <l-line-spinner
        size="200"
        stroke="3"
        speed="1" 
        color="black" 
      ></l-line-spinner>
      <Typography variant='h4' color="InfoText" fontFamily={"monospace"} sx={{mt: 2}}>{text}</Typography>
    </Box>
  )
}
