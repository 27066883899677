import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Chip,
  useTheme,
  ButtonGroup,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import instance from "../../instance";
import { ForceUpdateContext, UserContext } from "../Dashboard";
import { enqueueSnackbar } from "notistack";
import { Link } from "react-router-dom";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
  },
  {
    field: "details",
    headerName: "Details",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => (
      <Box>
        <Typography variant='body2'>
          <strong>Invoice:</strong> {params.row?.Invoice}
        </Typography>
        <Typography variant='body2'>
          <strong>OrderID:</strong>{" "}
          <Link to={`/orders/view/${params.row.orderItemId}`}>
            {params.row.orderId}
          </Link>
        </Typography>
        <Typography variant='body2'>
          <strong>SubOrderID:</strong> {params.row.orderItemId}
        </Typography>
        <Typography variant='body2'>
          <strong>Channel:</strong> {params.row.channelName}
        </Typography>
        <Typography variant='body2'>
          <strong>Buyer:</strong> {params.row?.buyer}
        </Typography>
        <Typography variant='body2'>
          <strong>State:</strong> {params.row.status}
        </Typography>
        <Typography variant='body2'>
          <strong>Category:</strong> {params.row?.category}
        </Typography>
        <Typography variant='body2'>
          <strong>Total:</strong> ₹{params.row.total}
        </Typography>
      </Box>
    ),
  },
  {
    field: "product",
    headerName: "Product",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Box>
        <Box
          component='img'
          sx={{ height: 100, width: "auto" }}
          alt='Product'
          src={params.row.image}
        />
        <Typography variant='body2'>
          <strong>SkuCode:</strong> {params.row.sku}
        </Typography>
        <Typography variant='body2'>
          <strong>Name:</strong> {params.row.title}
        </Typography>
        <Typography variant='body2'>
          <strong>Qty:</strong> {params.row.quantity}
        </Typography>
      </Box>
    ),
  },
  {
    field: "batch",
    headerName: "Batch",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Box>
        <Typography variant='body2'>
          <strong>Listing ID:</strong> {params.row.listingId}
        </Typography>
        <Typography variant='body2'>
          <strong>Order Time:</strong> {params.row.orderDate}
        </Typography>
      </Box>
    ),
  },
  {
    field: "shipping",
    headerName: "Shipping",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Box>
        <Typography variant='body2'>
          <strong>Brand:</strong> {params.row?.brand}
        </Typography>
        <Typography variant='body2'>
          <strong>Courier:</strong> {params.row?.courier}
        </Typography>
        <Typography variant='body2'>
          <strong>SLA Date:</strong> {params.row?.slaDate}
        </Typography>
        <Chip
          label={params.row.slaStatus}
          color={params.row?.slaStatus === "SLA Breached" ? "error" : "warning"}
        />
      </Box>
    ),
  },
  {
    field: "financials",
    headerName: "Financials",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Box>
        <Typography variant='body2'>
          <strong>Net Tax:</strong> {params.row?.netTax}
        </Typography>
        <Typography variant='body2'>
          <strong>Net Profit:</strong> {params.row?.netProfit}
        </Typography>
      </Box>
    ),
  },
];

const OrdersGrid = () => {
  const theme = useTheme();
  const user = React.useContext(UserContext);
  const { updated, forceUpdate } = React.useContext(ForceUpdateContext);
  const [orders, setOrders] = React.useState<Array<any>>([]);
  const [selectedOrders, setSelectedOrders] = React.useState<GridRowSelectionModel>([]);
  const [tabValue, setTabValue] = React.useState(0);

  React.useEffect(() => {
    if (user) {
      instance
        .get("/orders/get-orders")
        .then((result) => setOrders(result.data))
        .catch((err) => {
          enqueueSnackbar({
            message: "Unable to fetch orders",
            variant: "error",
          });
        });
    }
  }, [user, updated]);

  function handleFetchNew() {
    instance
      .post("/orders/fetch-new-orders")
      .then((result) => {
        enqueueSnackbar({
          message: result.data,
          variant: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar({
          message: "Unknown Error Occurred",
          variant: "error",
        });
      });
  }

  function handleFetchCancelled() {
    instance.post("/orders/fetch-cancelled-orders")
    .then((result) => {
      enqueueSnackbar({
        message: result.data,
        variant: "success",
      });
    })
    .catch((err) => {
        console.log(err);
        enqueueSnackbar({
          message: "Unknown Error Occurred",
          variant: "error"
        })
    })
  }

  function handleSync() {
    instance.post("/orders/sync-orders", {
      ids: selectedOrders
    })
    .then(result => {
      setTimeout(() => {
        forceUpdate();
      }, 2000);
      enqueueSnackbar({
        message: result.data,
        variant: "success"
      })
    })
    .catch(err => {
      enqueueSnackbar({
        message: err.response.data,
        variant: "error"
      })
    })
  }

  function handlePackOrders(){
    instance.post("/orders/pack-orders", {
      ids: selectedOrders
    }).then(result => {
      setTimeout(() => {
        forceUpdate()
      }, 2000)
      enqueueSnackbar({
        message: result.data,
        variant: "success"
      })
    })
    .catch(err => {
      enqueueSnackbar({
        message: err.response.data,
        variant: "error"
      })
    })
  }

  function handleDispatch() {

  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label='Orders Tabs'>
        <Tab label='New' />
        <Tab label='Packed' />
        <Tab label='Cancelled' />
        <Tab label='All Orders' />
        <Tab label='Ready To Ship' />
      </Tabs>
      <Box sx={{ padding: theme.spacing(2) }}>
        {tabValue === 0 && (
          <div style={{ height: 600, width: "100%" }}>
            <ButtonGroup variant='contained' color='secondary'>
              <Button disabled={!selectedOrders.length} onClick={handleSync}>Order Sync</Button>
              <Button onClick={handleFetchNew}>Fetch New Orders</Button>
              <Button disabled={!selectedOrders.length} onClick={handlePackOrders}>Pack Orders</Button>
              <Button disabled>Create Batch</Button>
            </ButtonGroup>
            <DataGrid
              checkboxSelection
              rows={orders.filter(row => String(row.status) !== "CANCELLED")}
              columns={columns}
              onRowSelectionModelChange={(newSelection) => {
                setSelectedOrders(newSelection);
              }}
              rowHeight={200}
              sx={{
                ".MuiDataGrid-row": {
                  height: "auto !important",
                  alignItems: "flex-start !important",
                },
                ".MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "flex-start",
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  padding: theme.spacing(1),
                },
              }}
            />
          </div>
        )}
        {tabValue === 1 && (
          <div>
            {/* Content for the other tab */}
            <ButtonGroup variant='contained' color='secondary'>
              <Button disabled={!selectedOrders.length} onClick={handleSync}>Sync Selected Orders</Button>
              <Button disabled={!selectedOrders.length} onClick={handleDispatch}>Dispatch Orders</Button>
              <Button>Download Documents</Button>
            </ButtonGroup>
            <DataGrid
              checkboxSelection
              rows={orders.filter((row) => String(row.status).toUpperCase() === "PACKED")}
              columns={columns}
              rowHeight={200}
              sx={{
                ".MuiDataGrid-row": {
                  height: "auto !important",
                  alignItems: "flex-start !important",
                },
                ".MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "flex-start",
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  padding: theme.spacing(1),
                },
              }}
            />
          </div>
        )}
        {tabValue === 2 && (
          <div style={{ height: 600, width: "100%" }}>
            <ButtonGroup variant='contained' color='secondary'>
              <Button onClick={handleFetchCancelled}>Fetch Cancelled Orders</Button>
              <Button>Download Documents</Button>
            </ButtonGroup>
            <DataGrid
              checkboxSelection
              rows={orders.filter((row) => String(row.status).toUpperCase() === "CANCELLED")}
              columns={columns}
              rowHeight={200}
              sx={{
                ".MuiDataGrid-row": {
                  height: "auto !important",
                  alignItems: "flex-start !important",
                },
                ".MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "flex-start",
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  padding: theme.spacing(1),
                },
              }}
            />
          </div>
        )}
        {tabValue === 3 && (
          <div style={{ height: 600, width: "100%" }}>
            <ButtonGroup variant='contained' color='secondary'>
              <Button onClick={handleSync}>Sync Selected Orders</Button>
            </ButtonGroup>
            <DataGrid
              checkboxSelection
              rows={orders.filter((row) => String(row.status).toUpperCase() === "CANCELLED")}
              columns={columns}
              rowHeight={200}
              sx={{
                ".MuiDataGrid-row": {
                  height: "auto !important",
                  alignItems: "flex-start !important",
                },
                ".MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "flex-start",
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  padding: theme.spacing(1),
                },
              }}
            />
          </div>
        )}
        {tabValue === 4 && (
          <div style={{ height: 600, width: "100%" }}>
            <ButtonGroup variant='contained' color='secondary'>
              <Button onClick={handleSync}>Sync Selected Orders</Button>
              <Button onClick={handleSync}>Confirm Shipment</Button>
              <Button onClick={handleSync}>Download Documents</Button>
            </ButtonGroup>
            <DataGrid
              checkboxSelection
              rows={orders.filter((row) => String(row.status).toUpperCase() === "READY_TO_DISPATCH")}
              columns={columns}
              rowHeight={200}
              sx={{
                ".MuiDataGrid-row": {
                  height: "auto !important",
                  alignItems: "flex-start !important",
                },
                ".MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "flex-start",
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  padding: theme.spacing(1),
                },
              }}
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default OrdersGrid;
