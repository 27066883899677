import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  TextField,
  Button,
  Grid,
  Container,
  Box,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import instance from '../../instance';

interface ProductInput {
  name: string;
  product_id: string;
  sku: string;
  category: string;
  gst_tax: string;
  package_type: string;
  last_purchase_price: string;
  account_alias: string;
  critical_threshold: string;
  sku_model_code: string;
  hsn_code: string;
  weight: string;
  selling_Price: string;
  mrp: string;
  color: string;
  size: string;
  unit: string;
  brand: string;
  image_url: string;
  product_group: string;
  master_sku: string;
  duplicate_sku: string;
}

const EditProduct: React.FC = () => {
  const [productData, setProductData] = useState<ProductInput>({
    name: '',
    product_id: '',
    sku: '',
    category: '',
    gst_tax: '',
    package_type: '',
    last_purchase_price: '',
    account_alias: '',
    critical_threshold: '',
    sku_model_code: '',
    hsn_code: '',
    weight: '',
    selling_Price: '',
    mrp: '',
    color: '',
    size: '',
    unit: '',
    brand: '',
    image_url: '',
    product_group: '',
    master_sku: '',
    duplicate_sku: '',
  });

  const { id } = useParams<{ id: string }>(); // get the id from URL params
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Fetch product details using the id from URL params
    const fetchProductDetails = async () => {
      try {
        const response = await instance.get(`/products/get/${id}`);
        const data = Object.keys(productData).reduce((prev: {[key: string]: any}, curr) => {prev[curr] = response.data[curr]; return prev;}, {}) as ProductInput
        setProductData(data);
      } catch (error: any) {
        enqueueSnackbar(error.response.data || error.message, { variant: 'error' });
      }
    };

    fetchProductDetails();
  }, [id, enqueueSnackbar]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (sync = false) => {
    try {
      await instance.post('/products/editProduct', { ...productData, productId: id });
      enqueueSnackbar('Product updated successfully', { variant: 'success' });
      if (sync) {
        // Implement your sync logic here
      }
    } catch (error: any) {
        enqueueSnackbar(error.response.data || error.message, { variant: 'error' });
    }
  };

  const handleCancel = () => {
    navigate('/products');
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Grid container spacing={2}>
          {Object.keys(productData).map((key) => (
            <Grid item xs={12} sm={6} lg={3} key={key}>
              <TextField
                fullWidth
                label={key.replace(/_/g, ' ')}
                name={key}
                value={(productData as any)[key] || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          ))}
        </Grid>
        <Box mt={4} display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={() => handleSubmit(false)}>
            Update
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleSubmit(true)}>
            Update with Sync
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EditProduct;
