import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { UserContext } from '../Dashboard';
import instance from '../../instance';
import { Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 50, sortable: true },
  { field: 'name', headerName: 'Name', width: 150, sortable: true },
  { field: 'productInfo', headerName: 'Product Info', width: 200, sortable: false, display: "flex", renderCell: (params: GridRenderCellParams) => (
      <div>
        <div><strong>Product ID:</strong> {params.row.product_id}</div>
        <div><strong>SKU:</strong> {params.row.sku}</div>
        <div><strong>Category:</strong> {params.row.category || 'N/A'}</div>
        <div><strong>Brand:</strong> {params.row.brand || 'N/A'}</div>
      </div>
    )
  },
  { field: 'pricingInfo', headerName: 'Pricing Info', width: 200, sortable: false, display: "flex", renderCell: (params: GridRenderCellParams) => (
      <div>
        <div><strong>Selling Price:</strong> {params.row.selling_Price || 'N/A'}</div>
        <div><strong>MRP:</strong> {params.row.mrp || 'N/A'}</div>
        <div><strong>Last Purchase Price:</strong> {params.row.last_purchase_price || 'N/A'}</div>
        <div><strong>GST Tax:</strong> {params.row.gst_tax || 'N/A'}</div>
      </div>
    )
  },
  { field: 'inventoryInfo', headerName: 'Inventory Info', width: 200, sortable: false, display: "flex", renderCell: (params: GridRenderCellParams) => (
      <div>
        <div><strong>Weight:</strong> {params.row.weight || 'N/A'}</div>
        <div><strong>Color:</strong> {params.row.color || 'N/A'}</div>
        <div><strong>Size:</strong> {params.row.size || 'N/A'}</div>
        <div><strong>Unit:</strong> {params.row.unit || 'N/A'}</div>
      </div>
    )
  },
  { field: 'additionalInfo', headerName: 'Additional Info', width: 200, sortable: false, display: "flex", renderCell: (params: GridRenderCellParams) => (
      <div>
        <div><strong>Package Type:</strong> {params.row.package_type || 'N/A'}</div>
        <div><strong>Account Alias:</strong> {params.row.account_alias || 'N/A'}</div>
        <div><strong>Critical Threshold:</strong> {params.row.critical_threshold}</div>
        <div><strong>HSN Code:</strong> {params.row.hsn_code || 'N/A'}</div>
      </div>
    )
  },
  { field: 'status', headerName: 'Status', width: 100, type: 'boolean', sortable: true },
  {field: 'actions', headerName: "Actions", width: 100, sortable: false, renderCell: (params: GridRenderCellParams) => (
    <div>
      <IconButton >
        <Link to={`/products/edit/${params.id}`} >
          <Edit color="secondary" />
        </Link>
      </IconButton>
    </div>
  )}
];


declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    selectedRows: Array<any>, 
    handleMasterAction: Function
  }
}

const CustomToolbar = ({selectedRows, handleMasterAction}: { selectedRows: Array<any>, handleMasterAction: any }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      {selectedRows.length > 0 && (
        <Button
          color="primary"
          onClick={handleMasterAction}
        >
          Sync All
        </Button>
      )}
    </GridToolbarContainer>
  );
};

export default function ListProducts() {
  const {enqueueSnackbar} = useSnackbar();
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const handleSelectionChange = (rowSelectionModel: any) => {
    setSelectedRows(rowSelectionModel);
  };

  const handleMasterAction = () => {
    // Perform the master action on the selected rows
    enqueueSnackbar(`Performing sync on rows: ${selectedRows.join(', ')}`, { variant: "info" });
    // Reset selection after action
    // instance.post("/products/sync", {
    //   productIds: selectedRows
    // })
    // .then(response => response.data)
    // .then(result => {
    //   enqueueSnackbar(result, {variant: "success"});
    // })
    // .catch(err => {
    //   console.log(err);
    //   enqueueSnackbar("Unhandled Error", {variant: "error"})
    // })
    setSelectedRows([]);
  };


  const user = React.useContext(UserContext);
  const channelIds = React.useMemo(() => user?.warehouses.map(Warehouse => Warehouse.companies.map(company => company.channels.map(channel => channel.id))).flat(4), [user]);
  const [products, setProducts] = React.useState<Array<any>>([]);
  
  React.useEffect(() => {
    instance.post('/products/query', {
        channelIds
    }).then(result => setProducts(result.data))
  }, [channelIds])

  return (
    <Box>
      <DataGrid
        slots={{
          toolbar: CustomToolbar
        }}
        slotProps={{
          toolbar: { selectedRows, handleMasterAction }
        }}
        rows={products}
        columns={columns}
        autoHeight
        rowHeight={100}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionChange}
      />
    </Box>
  );
}

