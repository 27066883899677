import React, { ChangeEventHandler, useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { ForceUpdateContext, UserContext } from '../Dashboard';
import { useSnackbar } from 'notistack';
import instance from '../../instance';

type Warehouse = {
  id: number;
  name: string;
};


const AddCompany: React.FC = () => {
  const user = React.useContext(UserContext);
  const { forceUpdate } = React.useContext(ForceUpdateContext);
  const {enqueueSnackbar} = useSnackbar();
  const warehouses = user?.warehouses || [];
  const [formData, setFormData] = useState({
    address: '',
    brandName: '',
    contactNo: '',
    email: '',
    GSTIN: '',
    name: '',
    ownerName: '',
    warehouseId: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add your submit logic here
    console.log('Form data submitted:', formData);

    instance.post('/companies/add', formData)
    .then(response => {
        forceUpdate();
        enqueueSnackbar({
            message: "Updated Successfully",
            variant: "success"
        })
    }).catch(err => {
        console.log(err);
        enqueueSnackbar({
            message: "Error Occurred!",
            variant:"error"
        })
    })
  };

  const handleReset = () => {
    setFormData({
      address: '',
      brandName: '',
      contactNo: '',
      email: '',
      GSTIN: '',
      name: '',
      ownerName: '',
      warehouseId: ''
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: 2,
        p: 2
      }}
    >
      <TextField
        label="Address"
        name="address"
        value={formData.address}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Brand Name"
        name="brandName"
        value={formData.brandName}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Contact No"
        name="contactNo"
        value={formData.contactNo}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="GSTIN"
        name="GSTIN"
        value={formData.GSTIN}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Owner Name"
        name="ownerName"
        value={formData.ownerName}
        onChange={handleChange}
        fullWidth
        required
      />
      <FormControl fullWidth>
        <InputLabel>Warehouse</InputLabel>
        <Select
          name="warehouseId"
          value={formData.warehouseId}
          onChange={handleChange}
          required
        >
          {warehouses.map((warehouse) => (
            <MenuItem key={warehouse.id} value={warehouse.id.toString()}>
              {warehouse.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ gridColumn: 'span 2', display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
        <Button type="button" variant="outlined" color="secondary" onClick={handleReset}>
          Reset
        </Button>
      </Box>
    </Box>
  );
};

export default AddCompany;
