import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { FormControl, InputLabel, Grid } from "@mui/material";
import { ForceUpdateContext, UserContext } from "../Dashboard";
import { useNavigate } from "react-router-dom";
import instance from "../../instance";
import { enqueueSnackbar } from "notistack";

const AddChannels: React.FC = () => {
  const user = React.useContext(UserContext);
  const { forceUpdate } = React.useContext(ForceUpdateContext);
  const [warehouse, setWarehouse] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [authentication, setAuthentication] = useState<string>("");
  const [type, setType] = useState<string>("");
  const navigate = useNavigate();

  const handleWarehouseChange = (event: SelectChangeEvent<string>) => {
    setWarehouse(event.target.value as string);
    setCompany(""); // reset company when warehouse changes
  };

  const handleCompanyChange = (event: SelectChangeEvent<string>) => {
    setCompany(event.target.value as string);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleAuthenticationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuthentication(event.target.value);
  };

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setType(event.target.value as string);
  };

  const handleSubmit = () => {
    // Handle form submission
    instance
      .post("/channels/add", {
        warehouseId: warehouse,
        companyId: company,
        name,
        type,
        authentication,
      })
      .then((_) => {
        forceUpdate();
        enqueueSnackbar({
          message: "Added Successfully",
          variant: "success",
        });
        setTimeout(() => {
          navigate("/channels");
        }, 500);
      })
      .catch((_) => {
        enqueueSnackbar({
          message: "Error Occurred",
          variant: "error",
        });
      });
  };

  const handleBack = () => {
    // Handle back to all channels action
    console.log("Back to all channels");
    navigate("/channels");
  };

  const getCompanyOptions = () => {
    const companies = user?.warehouses.find(
      (value) => Number(warehouse) === value.id
    )?.companies;
    if (companies) {
      return companies.map((value, index) => (
        <MenuItem key={index} value={value.id}>
          {value.name}
        </MenuItem>
      ));
    } else {
      return [];
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant='h4' gutterBottom>
        Add Channel
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Warehouse</InputLabel>
            <Select
              value={warehouse}
              onChange={handleWarehouseChange}
              label='Warehouse'>
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {user?.warehouses.map((value, index) => (
                <MenuItem key={index} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Company</InputLabel>
            <Select
              value={company}
              onChange={handleCompanyChange}
              label='Company'
              disabled={!warehouse}>
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {getCompanyOptions()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label='Name'
            value={name}
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label='Authentication'
            value={authentication}
            onChange={handleAuthenticationChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select value={type} onChange={handleTypeChange} label='Type'>
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value='flipkart'>Flipkart</MenuItem>
              <MenuItem value='amazon'>Amazon</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant='outlined' color='secondary' onClick={handleBack}>
              Back to All Channels
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddChannels;
